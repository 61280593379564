.text-white { color: #fff }

.text-bold { font-weight: bold }

.grecaptcha-badge { visibility: hidden }

.link { 
    cursor: pointer;
    text-decoration: underline;
    &:hover { text-decoration: none }
}

/* Bootstrap customizations */

$theme-colors: (
    "primary": #4E9EA7,
    "facebook": #0165E1,
    "apple": #000,
    "unocart": #faaa1d
);

@import "~bootstrap/scss/bootstrap";

.modal-header { border-bottom: none }

.modal-title { font-weight: bold }

