$grey: #ced4da; // same as bootstrap input outline

.divider {
  display: flex;
  flex-direction: row;
  color: $grey;
  margin: 1rem 0;
  font-weight: bold;
}

.divider:before, .divider:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $grey;
  margin: auto;
}

.divider:before {
  margin-right: 0.8rem
}

.divider:after {
  margin-left: 0.8rem
}