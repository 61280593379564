.modal-content {
  .modal-header {
    padding-bottom: 0;
  }

  .modal-footer {
    padding-top: 0;
    border-top: 0;
  }
}
